html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  background-image: url('../public/background.png');
}
