.App {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-content {
  margin: 0 20px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.33);
  background-color: rgba(0, 0, 0, 0.55);
  max-width: 550px;
  width: 100%;
  text-align: center;
}

.App-content p {
  font-family: "Karla", Arial, sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #EBEBEB;
}
